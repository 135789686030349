import { Sheet } from "@mui/joy"

const Footer = () => {

    return (
        <Sheet color="primary" variant="solid" sx={[
            {width:1/1}, 
            {textAlign: "center"}, 
            {backgroundColor:"#00000090"},
            {padding: "5px 0px 5px 0px"}
        ]}>
            Developed by Shaan H. | 2019
        </Sheet>  
    )
}

export default Footer