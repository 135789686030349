import Header from "./header"
import Footer from "./footer"
import Home from "../pages/home"
import bgVideo from "../videos/weather.mp4"
import SignUpForm from "./signupform";
import RemovalForm from "./removalform";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Grid } from "@mui/joy"

const PageContainer = () => {

    return(
    <Router>
        <Grid className="page-container" container justifyContent="space-between" alignItems="center" direction="column">
            <video src={bgVideo} autoPlay muted loop/>
            <Grid item xs={12}><Header/></Grid>
            <Routes>
                <Route path='/' element={<Home/>} />
                <Route path='/signup' element={<SignUpForm/>} />
                <Route path='/removeme' element={<RemovalForm/>} />
                <Route path='/*' element={<div className="nf-text"> PAGE NOT FOUND</div>} />
            </Routes> 
            <Grid item xs={12}><Footer/></Grid>
        </Grid>
    </Router>
    )


}

export default PageContainer