import { useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import {Sheet, Stack, Button, Input } from '@mui/joy'

const RemovalForm = () => {

    const [formData, setFormData] = useState({
        firstName: '',
        email: '',
        captcha: '',
    })

    const [formState, setFormState] = useState({
        showStatus: 'none',
        statusMessage: '',
    })

    const changeHandler = e => {
        setFormData( prevValues => {
            return { ...prevValues, [e.target.name]: e.target.value}
        })
    }

    const captchaHandler = captcha_token => {
        setFormData( prevValues => {
            return { ...prevValues, "captcha": captcha_token}
        })
    }

    const removeHandler = e => {
        e.preventDefault()

        const body = {
            firstName: formData.firstName,
            email: formData.email,
            captcha: formData.captcha,
        }

        const request_options = {
            method: "POST",
            headers: {
                "Content-Type": 'application/json'
            },
            body: JSON.stringify(body)
        }

        fetch(process.env.REACT_APP_URL_REMOVE_ME, request_options)
        .then(response => {
            
            if (response.status === 200) {
                setFormState({ "statusMessage": "You have been removed", "showStatus": "inherit"})
            } else {
                setFormState({ "statusMessage": "User Not Found", "showStatus": "inherit"})
            }

            window.grecaptcha.reset()
            
            document.getElementById("removeme").reset()


        })
        .catch(error => console.log(error))
    }

    return (
        <Sheet variant="outlined" color="neutral" sx={[{padding: "10px 10px 10px 10px"}, {borderRadius: "5px"}, {backgroundColor:"#00000085"}]}>
            <form onSubmit={removeHandler} id="removeme">
                <div className="status-text" style={{display: formState.showStatus}}>{formState.statusMessage}</div>
                <Stack spacing={1}>
                    <Input size="sm" type="email" name="email" id="email"
                    placeholder="Email Address"
                    onChange={changeHandler}
                    required
                    />
                    <Input size="sm" type="text" name="firstName" id="firstName"
                    placeholder="First Name"
                    onChange={changeHandler}
                    required
                    />
                    <Button type="submit" size="sm" color="danger" >Remove Me</Button>
                    <ReCAPTCHA className="captcha" 
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        onChange={captchaHandler}
                        />
                </Stack>
            </form>
        </Sheet>
    )
}


export default RemovalForm






