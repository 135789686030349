import { Sheet, Link } from "@mui/joy";
import umbrellaLogo from "../images/logo.png";
import { Link as DomLink } from "react-router-dom";

const Header = () => {

    return (
        <Sheet sx={[{justifyContent: "space-between"}, {display: "flex"}, {alignItems:"center"}, {bgcolor: "#00000085"}]}>
            <img src={umbrellaLogo} alt="umbrella" className="logo-img" />
            <div className="title">
                <DomLink to="/">FASHION FORECAST</DomLink>
            </div>
            <Link 
            bgcolor="#00000000"
            color="solid"
            underline="none" 
            variant="outlined" 
            level="title-lg" 
            href="https://www.shaanhashmi.com/"
            sx={[
                {margin: "0px 25px 0px 0px"},
                {color: "white"}
            ]}
            >
                S.H
            </Link>
        </Sheet>
    )
}

export default Header