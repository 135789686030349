import { useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import {Sheet, Stack, Button, Input } from '@mui/joy'

const SignUpForm = () => {

    const [formData, setFormData] = useState({
        firstName: '',
        phoneNum: '',
        email: '',
        time: '',
        city: '',
        country: '',
        captcha: '',
    })

    const [formState, setFormState] = useState({
        showStatus: 'none',
        statusMessage: '',
    })

    const changeHandler = e => {
        setFormData( prevValues => {
            return { ...prevValues, [e.target.name]: e.target.value}
        })
    }

    const captchaHandler = captcha_token => {
        setFormData( prevValues => {
            return { ...prevValues, "captcha": captcha_token}
        })
    }

    const submitHandler = e => {
        // Prevent the default reload on submit
        // stops the page reloading before firefox can get the request out
        e.preventDefault()

        const body = {
            firstName: formData.firstName,
            phoneNum: formData.phoneNum,
            email: formData.email,
            time: formData.time,
            city: formData.city,
            country: formData.country,
            captcha: formData.captcha,
        }

        const request_options = {
            method: "POST",
            headers: {
                "Content-Type": 'application/json'
            },
            body: JSON.stringify(body)
        }

        fetch(process.env.REACT_APP_URL_SIGN_UP, request_options)
        .then(response => {

            if (response.status === 200) {
                setFormState({ "statusMessage": "Successfully Signed Up", "showStatus": "inherit"})
            } else {
                setFormState({ "statusMessage": "Failed to Sign Up", "showStatus": "inherit"})
            }
            
            document.getElementById("signup").reset()
            window.grecaptcha.reset()
        })
        .catch(error => console.log(error))
    }

    return (
        <Sheet variant="outlined" color="neutral" sx={[{padding: "10px 10px 10px 10px"}, {borderRadius: "5px"}, {backgroundColor:"#00000085"}]}>
            <form onSubmit={submitHandler} id="signup">
                <Stack spacing={1}>
                    <div className="status-text" style={{display: formState.showStatus}}>{formState.statusMessage}</div>
                    <Input size="sm" type="email" name="email" id="email"
                    placeholder="Email Address"
                    onChange={changeHandler}
                    required
                    />
                    <Input size="sm" type="text" name="firstName" id="firstName"
                    placeholder="First Name"
                    onChange={changeHandler}
                    required
                    />
                    <Input size="sm" type="tel" name="phoneNum" id="phoneNum"
                    placeholder="Phone Number"
                    onChange={changeHandler}
                    required
                    />
                    <Input size="sm" type="time" name="time" id="time"
                    onChange={changeHandler}
                    required
                    />
                    <Input size="sm" type="text" name="city" id="city"
                    placeholder="City"
                    onChange={changeHandler}
                    required
                    />
                    <Input size="sm" type="text" name="country" id="country"
                    placeholder="Country"
                    onChange={changeHandler}
                    required
                    />
                    <Button type="submit" size="sm" >Sign-Up</Button>
                    <ReCAPTCHA className="captcha" 
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        onChange={captchaHandler}
                        />
                </Stack>
            </form>
        </Sheet>
    )
}


export default SignUpForm






