import { Grid, Stack, Button } from "@mui/joy"
import { Link } from "react-router-dom"

const Home = () => {
    return(
        <Grid item xs={6} className="splash-text-container" >
            <Stack className="splash-text" spacing={2} justifyContent="center" alignItems="center" direction="column">
                <p>Never worry about the weather again</p>
                <p>Fashion Forecast is your personal AI Weather Person</p>
                <p>Receive AI powered messages directly to your phone daily</p>
                <Stack direction="row" spacing={2} className="button-stack">
                    <Button size="lg" color="primary" variant="solid" to="/signup" component={Link} sx={"width: 175px"}>Sign-Up Today</Button>
                    <Button size="lg" color="neutral" variant="solid" to="/removeme" component={Link} sx={"width: 175px"}>Remove Me</Button>
                </Stack>
            </Stack>
        </Grid>
    )
}

export default Home